body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* scroll-behavior: smooth; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* //////////////////// */


/* **************************  
avanttech site custom css 
 ************************** */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Rubik:wght@300&display=swap');
html {
    overflow-x: hidden !important;
}

body {
    overflow-x: hidden !important;
    background-color: #000 !important;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-family: 'PT Sans', sans-serif;
}

h1 {}

p {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
}

.modal-open {
    overflow: hidden !important;
}

.modal-body {
    /* height: 650px;
    overflow-x: scroll; */
}

.avt-btn {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    display: flex;
    align-items: center;
    position: relative;
}

.avt-btn span {
    position: relative;
    left: 30px;
    transition: all 1s;
    transform: translateX(2px);
}

.avt-btn::before {
    content: '>';
    width: 56px;
    height: 56px;
    border-radius: 100px;
    background-color: transparent;
    color: transparent;
    border: 2px solid #be1e2d8c;
    display: block;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    left: 4px;
    font-weight: 100;
    font-family: Inter;
    font-size: 18px;
}

.avt-btn:hover {
    color: #fff;
}

.avt-btn:hover span {
    transform: translateX(40px);
}

.avt-btn:hover::before {
    color: #fff;
    background-color: #BE1E2D;
}

.text-mg-center {
    margin: 0 auto;
}

.text-flex-center {
    display: flex;
    justify-content: center;
}

.text-dark {
    color: #000;
}

.text-light {
    color: #fff;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}


/* mega menu */

.avt-menu-col-cls {
    display: flex;
    align-items: center;
}

.avt-menu-col-cls .mega-btn {
    /* background-color: #020202;
    width: 40px;
    height: 40px;
    border: 1px solid #c2c2c2;
    border-radius: 100px; */
}

.avt-menu-col-cls .mega-btn {
    /* background-color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 100px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.avt-megamenu-icon svg {
    height: 23px;
    width: 23px;
    position: relative;
    top: -3px;
}

.avt-megamenu-icon svg path {
    /* fill: #000; */
    fill: #fff;
}

.avt-megamenu-icon svg:hover path {
    fill: #BE1E2D;
}

.avt-menu-col-cls .mega-btn:hover {
    opacity: 0.7;
}

.avt-menu-contact-btn {
    background-color: #fff;
    color: #000;
    border-radius: 100px;
    padding: 13px 30px;
    text-decoration: none;
    font-weight: 500;
    display: block;
    width: fit-content;
}

.avt-menu-contact-btn:hover {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.avt-middle-row h3 {
    font-family: 'Dancing Script', cursive;
    margin-bottom: 25px;
    font-size: 35px;
    /* display: none; */
}


/* .avt-menu-col-cls .mega-btn svg {
    font-size: 24px;
    background-color: #020202;
    width: 40px;
    height: 40px;
    border: 1px solid #c2c2c2;
    border-radius: 100px;
} */

.avt-mega-menu {
    /* display: none; */
    position: fixed;
    background-color: #000;
    z-index: 99999;
    width: 100%;
    height: 100%;
    transition: all 1s;
}

.avt-mega-menu .container-fluid {
    padding: 5px 0px 30px 0px;
    height: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

.avt-top-row {
    padding: 30px 185px;
}

.avt-top-row .text-right {
    text-align: right;
}

.avt-top-row .text-right .avt-close-menu-btn {
    color: #fff;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 auto;
    transition: all 0.5s;
    cursor: pointer;
}

.avt-top-row .text-right .avt-close-menu-btn:hover {
    background-color: #000000a6;
}

.avt-close-menu-btn:hover svg path {
    fill: #B51A40;
}

.avt-middle-row {
    padding: 80px 0 20px 180px;
    align-items: start;
}

.avt-middle-row ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.avt-middle-row ul li {
    margin-bottom: 7px;
}

.avt-middle-row ul li a {
    color: #fff;
    font-family: Inter;
    font-size: 75px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    text-shadow: 0 4px 4px #00000040;
    text-transform: uppercase;
    text-decoration: none;
}

ul.avt-resp-social-list.avt-header-social-list {
    display: none;
}


/* .avt-middle-row ul li a:hover {
    opacity: 0.7;
} */

.avt-btm-row {
    align-items: baseline;
}

.avt-btm-row .avt-footer-icons-list ul {
    justify-content: left;
}

.avt-btm-row .avt-menu-email {
    color: #fff;
    font-size: 18px;
}

.avt-btm-row .avt-menu-email a {
    color: #fff;
    text-decoration: none;
    padding: 0 15px;
}

.avt-btm-row .avt-menu-email a:hover {
    opacity: 0.7;
}

.avt-web-services-cls {
    display: none;
}

.avt-mega-menu .avt-close-menu-btn {
    cursor: pointer;
}

.avt-mega-menu .avt-close-menu-btn:hover {
    opacity: 0.7;
}

.avt-mega-menu-main-cls {
    margin: 0px 0 60px 0;
}

.avt-mega-menu-main-cls .navbar-nav {
    display: flex;
    flex-direction: column !important;
    width: fit-content;
}

.avt-mega-menu .avt-footer-icons-list ul {
    justify-content: left;
    margin-top: 20px;
}


/* ** */

.avt-mega-menu-main-cls .navbar-nav .nav-link {
    color: #A1A1A1 !important;
    font-family: Inter;
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.8em;
    padding: 0 !important;
    transition: all 0.3s;
}

.avt-mega-menu-main-cls .navbar-nav .nav-link:hover {
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle.show::after {
    display: none !important;
}


/* .avt-mega-menu-main-cls .navbar-nav .dropdown .nav-link:after {
    bottom: -8px;
    transition-duration: 0.1s;
}

.avt-mega-menu-main-cls .navbar-nav .nav-link:hover:after,
.avt-mega-menu-main-cls .navbar-nav .nav-link:focus:after,
.avt-mega-menu-main-cls .navbar-nav .nav-link:active:after {
    left: 0;
    right: auto;
    width: 100%;
}

.avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::after,
.avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle.show::after {
    border: none !important;
}

.avt-mega-menu-main-cls .navbar-nav .dropdown .nav-link.dropdown-toggle::after {
    border: none !important;
} */

.avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::before {
    content: '';
    background-image: url('../public/assets/images/menu/dropdown-icon.png');
    background-size: 30px;
    background-position: center;
    width: 40px;
    height: 40px;
    display: flex;
    position: absolute;
    right: 35px;
    top: 0;
    bottom: 0;
    margin: 25px 0 auto 0;
    background-repeat: no-repeat;
    transition: all 0.3;
    opacity: 0.7;
}

.avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
}

.avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle.show::before {
    transform: rotate(180deg);
}

.avt-mega-menu-main-cls .navbar-nav .dropdown-menu {
    position: relative !important;
    background-color: transparent !important;
    text-align: left !important;
    border: none !important;
}

.avt-mega-menu-main-cls .navbar-nav .dropdown-divider {
    display: none !important;
}

.avt-mega-menu-main-cls .navbar-nav .dropdown-menu .dropdown-item {
    background-color: transparent;
    color: #fff !important;
    padding: 0;
    /* ** */
    color: #A1A1A1;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 39px;
    transition: all 0.3;
}

.avt-mega-menu-main-cls .navbar-nav .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
    color: #fff !important;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avt-mega-menu .avt-project-btn {
    font-family: Inter;
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    text-decoration: none;
}

.avt-mega-menu .avt-middle-row .avt-right-col img {
    width: 100%;
}


/* .avt-mobile-menu-cls {
    display: none;
} */

.avt-middle-row .avt-menu-title {
    font-size: 18px;
    font-family: Inter;
    color: #fff;
}

.avt-btm-row .avt-mid-col .avt-footer-icons-list ul {
    justify-content: center;
    margin: 5px auto 10px auto;
    column-gap: 0px;
}


/* *********** */

.avt-loading-cls {
    position: absolute;
    width: 100%;
    /* background-color: #00000099; */
    background-color: #000;
    /* background-image: url(/public/assets/images/footer/footer-logo-img-updated.png); */
    background-size: 20%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    color: #eee;
    transition: all 1s;
    animation: pulse 1s alternate;
}

.avt-loading-text {
    opacity: 0;
}


/* 
.avt-loading-text {
    opacity: 0.6;
    animation: pulse 1s infinite;
}
*/

@keyframes pulse {
    0% {
        /* transform: scale(0.85); */
        background-size: 20%;
    }
    /* 70% {
        transform: scale(1.1);
    } */
    100% {
        background-size: 25%;
        /* transform: scale(1); */
        /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    }
}


/* or */


/* preloader */

.avt_loader_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 900;
    background: #000;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avt_loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 950;
    border: 4px solid #fff;
    top: 45%;
    animation: avt_loader 2s infinite ease;
}

.avt_loader_inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: avt_loader_inner 2s infinite ease-in;
}

@keyframes avt_loader {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes avt_loader_inner {
    0% {
        height: 0%;
    }
    25% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}


/* preloader end */

.my-custom-class-letter-opacity-1 {
    font-style: italic;
}

.my-custom-class-letter {
    font-style: normal !important;
    /* Styles for spans with opacity 0.2 */
}


/* header */

.avt-header-wrap {
    position: fixed;
    width: 100%;
    z-index: 9999;
    padding: 30px 30px 25px 30px;
    background-image: linear-gradient(to bottom, #0000009e, transparent);
}

.avt-header-wrap .navbar-collapse a.nav-link {
    color: #fff;
    /* color: #A1A1A1; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0 20px;
}


/* .avt-header-social-list {
    display: none;
} */

.avt-header-wrap .row.col-header-row {
    padding: 0 140px;
}

.avt-header-wrap .navbar-collapse a.nav-link:hover {
    color: #BE1E2D;
}

.avt-header-wrap a.nav-link:focus {
    color: #BE1E2D;
}

.avt-header-wrap .avt_navbar_sec ul {
    display: flex;
    column-gap: 30px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.avt-header-wrap .avt_navbar_sec ul li a {
    text-decoration: none;
    color: #FFF;
}

.avt-header-wrap .avt_navbar_sec ul li a:hover {
    opacity: 0.7;
}

ul.avt-header-social-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    column-gap: 20px;
    justify-content: end;
}

.avt-header-social-list li a {
    text-decoration: none;
    color: #FFF;
}

ul.avt-header-social-list span {
    padding-left: 10px;
}

.avt-header-wrap .dropdown-menu.show {
    margin-top: 25px;
    margin-left: 15px;
    padding: 13px 8px;
}

.avt-header-wrap a.dropdown-item {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

.avt-header-wrap a.dropdown-item:active {
    color: #B51A40;
    background-color: transparent;
}

.avt-header-wrap a.dropdown-item:hover {
    color: #B51A40;
    background-color: transparent;
}

.avt-header-wrap .dropdown-toggle::after {
    margin-left: 10px;
}


/* header end */


/* home banner */

.avt-home-banner {
    background-image: url('../public/assets/images/home/avanttech-video-poster3.png');
    background-size: cover;
    background-position: top center;
    height: 100vh;
    /* height: 900px; */
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    /* padding-top: 100px; */
}

.avt-banner-video-cls {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* position: absolute;
    top: 0; */
}

.avt-content-container {
    display: none;
}

.avt-banner-video-wrap {
    display: none;
}

.avt-banner-video-wrap .avt-banner-video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: brightness(0.6);
}

.avt-home-banner::before {
    content: '';
    /* background-color: #00000087; */
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.avt-home-banner .row {
    position: relative;
    z-index: 10;
}

.avt-home-banner h1 {
    font-size: 128px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 115.1px;
    font-weight: 200;
}

.avt-home-banner p {
    color: #A7A7A7;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    padding: 20px 0;
}


/* home banner end */


/* brands */

.avt-home-brands-sec {
    padding: 100px 0 40px 0;
}

.avt-home-brands-sec .row {
    text-align: center;
}

.avt-home-brands-sec .row h2 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 90px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}

.avt-home-brands-sec .row h2 strong {
    position: relative;
    text-decoration: underline;
    text-decoration-thickness: 2px;
}


/* .avt-home-brands-sec .row h2 strong::before {
     content: '';
     background-color: #fff;
     position: absolute;
     width: 100%;
     height: 1px;
     bottom: 0px;
 } */

.avt-home-brands-sec .row p {
    width: 872px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23.625px;
    font-style: normal;
    font-weight: 400;
    line-height: 34.8px;
    margin: 20px auto;
}


/* brands end */


/* home about sec */

.avt-home-about-sec {
    padding: 40px 0 140px 0;
    margin-bottom: 200px;
}

.avt-home-about-sec .row {
    position: relative;
}

.avt-home-about-img-wrap {
    position: absolute;
    left: -120px;
    width: 900px;
}

.avt-home-about-sec .avt-content-sec {
    position: relative;
    left: 80px;
}

.avt-home-about-sec .avt-content-sec {
    padding-right: 180px;
}

.avt-home-about-sec h3 {
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
    /* 109.375% */
}

.avt-home-about-sec p {}

.avt-home-about-sec a.avt-btn {
    margin-top: 30px;
}


/* home about sec end */


/* home services sec */

.avt-home-services-sec {
    padding: 40px 0;
}

.avt-home-services-sec .avt-home-services-top-row {
    text-align: center;
    padding: 20px 0;
}

.avt-home-services-sec .avt-home-services-top-row h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}

.avt-home-services-sec .avt-home-services-top-row h6 {
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.avt-services-row {
    padding: 90px 100px;
    align-items: center;
}


/* animation cls */


/* start */

.avt-animate-wrap {
    /* position: relative;
    z-index: 0; */
}

.avt-animate-wrap .avt-services-row {
    width: 1280px;
    /* position: relative; */
    /* z-index: 9999; */
}

.avt-animate-cls {
    /* position: relative; */
    /* z-index: 0; */
}

.avt-animate-wrap .avt-animate-cls div:last-child {
    /* height: 620px !important; */
    /* z-index: -1 !important; */
}

.avt-animate-wrap .avt-animate-cls div {}

.avt-animate-wrap .avt-services-row {
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 0 !important;
}


/* .avt-animate-wrap .avt-services-row .avt-btn {
    position: absolute !important; 
    z-index: 99999999 !important;
} */

.avt-animate-div-cls {
    /* position: unset !important; */
    /* z-index: 0 !important; */
}

.row.avt-services-row {
    position: unset !important;
    z-index: 0 !important;
}

.avt-home-services-sec {
    margin-bottom: -1700px;
}

.avt-animate-4-cls>div {
    z-index: 50 !important;
}

.avt-animate-5-cls>div {
    z-index: 40 !important;
}

.avt-animate-6-cls>div {
    z-index: 30 !important;
}

.avt-animate-7-cls>div {
    z-index: 20 !important;
}

.avt-animate-8-cls>div {
    z-index: 10 !important;
}


/* end */


/* *** */


/* .avt-animate-wrap .avt-services-row {
    margin-bottom: -900px;
} */


/* animation cls end */

.avt-services-row-01 {
    padding-top: 140px;
    padding-bottom: 180px;
}

img.avt-home-service-1-bg-img {
    position: absolute !important;
    animation: rotateAnimation 5s linear infinite;
    width: 130px !important;
    opacity: 0.6;
    left: -17px !important;
    top: -10px !important;
}

img.avt-home-service-2-bg-img {
    position: absolute !important;
    animation: rotateAnimation 5s linear infinite;
    width: 100px !important;
    left: -40px;
    opacity: 0.7;
    top: 120px !important;
}


/* img.avt-home-service-3-bg-img {
    position: absolute !important;
    animation: rotateAnimation 5s linear infinite;
    width: 130px !important;
} */

img.avt-home-service-4-bg-img {
    position: absolute !important;
    animation: rotateAnimation 5s linear infinite;
    width: 80px !important;
    right: 0;
    top: 120px !important;
}

@keyframes rotateAnimation {
    /* Rotate the element 360 degrees continuously */
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.avt-services-row-02 .avt-content-sec h5 {
    transform: translateX(285px);
}

.avt-services-row-02 p,
.avt-services-row-02 .avt-btn {
    transform: translateX(140px) !important;
}

.avt-services-row-02 {
    background-repeat: no-repeat;
    background-position: 100% 70%;
    background-size: 700px;
    position: relative;
}

.avt-services-02-img {
    position: absolute;
    right: 0;
    text-align: right;
    bottom: 0;
    animation: bg_service_2animate 7s linear infinite;
}

@keyframes bg_service_2animate {
    0% {
        transform: translateY(0px);
    }
    70% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

.avt-services-row h3 {
    color: #fff;
    font-family: Inter;
    font-size: 162px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
    white-space: nowrap;
}

.avt-services-row-01 h3 {
    transform: translateX(-190px);
}

.avt-services-row-01 .avt-home-services-img-wrap {
    z-index: 1;
    position: relative;
}

.avt-services-row-01 .avt-home-services-img-wrap img {
    /* width: 900px; */
    position: relative;
    top: 90px;
}

.avt-services-row-02 h3 {
    position: relative;
    z-index: 2;
}

.avt-services-row-02 .avt-home-services-img-wrap {
    z-index: 3;
    position: relative;
    width: 700px;
    top: 30px;
}

.avt-services-row-02 .avt-home-services-img-wrap img {
    animation: bg_rev_service_2animate 7s linear infinite;
}

@keyframes bg_rev_service_2animate {
    0% {
        transform: translateY(0px);
    }
    70% {
        transform: translateY(-15px);
    }
    100% {
        transform: translateY(0px);
    }
}

.avt-services-row-03 h3 {
    transform: translateX(-160px);
}

.avt-services-row-03 .avt-home-services-img-wrap {
    z-index: 1;
    position: relative;
}


/* .avt-services-row h3 span {
     display: block;
     font-family: Inter;
     font-size: 32px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
     background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
     background-clip: text;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
 } */

.avt-services-row h5 {
    display: block;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: -20px;
    margin-bottom: 30px;
}

.avt-services-row a.avt-btn {
    margin-top: 45px;
    position: relative;
    z-index: 9999999;
}

.avt-home-services-img-wrap img {
    width: 100%;
}

.avt-services-row-05 h3 {
    transform: translateX(-160px);
}

.avt-services-row-05 .avt-home-services-img-wrap {
    z-index: 1;
    position: relative;
}

.avt-services-row-05 .avt-service-5-back-img {
    position: absolute;
    opacity: 0.2;
    transition: all 0.4;
    /* transform: scale(1.30) translate(-59px, 42px); */
    animation: bg_rev_service_5animate 6s linear infinite;
}

.avt-services-row-05 .avt-home-services-img-wrap img {
    transform: scale(1.30) translate(-59px, 42px);
    transition: all 0.4;
}

.avt-services-row-05 .avt-home-services-img-wrap .avt-service-5-front-img {
    transition: all 0.7s;
    /* animation: bg_rev_service_5animate 6s linear infinite; */
}

.avt-services-row-05 .avt-home-services-img-wrap .avt-service-5-front-img:hover {
    transform: scale(1.30) translate(-65px, 42px);
    opacity: 0.8;
}

@keyframes bg_rev_service_5animate {
    0% {
        transform: scale(1.30) translate(-59px, 42px);
    }
    70% {
        transform: scale(1.30) translate(-59px, 52px);
        /* opacity: 0.4; */
    }
    100% {
        transform: scale(1.30) translate(-59px, 42px);
    }
}

@keyframes bg_rev_service_5animatefront {
    0% {
        transform: scale(1.30) translate(-69px, 122px);
        opacity: 0.4;
    }
    70% {
        transform: scale(1.30) translate(-69px, 82px);
    }
    100% {
        transform: scale(1.30) translate(-69px, 122px);
        opacity: 0.4;
    }
}


/* home services sec end */


/* home why choose sec */

.avt-why-choose-sec {
    background-color: #fff;
    /* height: 2800px; */
    height: 2800px;
    /* padding-top: 200px; */
}

.avt-why-choose-sec.avt-why-choose-animate-compl {
    background-color: #000;
}

.avt-why-choose-wrap {
    width: 100%;
    padding: 60px 0;
    background-color: #fff;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    color: #fff;
    /* height: 850px; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* transform: scale(0.04); */
    transform: scale(0.0);
    transform-origin: top left;
    transition: all 0.5s;
}

.avt-why-choose-sec .avt-top-head-row h2 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 46px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.92px;
    text-transform: uppercase;
}

.avt-why-choose-list-wrap {
    margin-top: -20px;
}

.avt-why-choose-sec .avt-why-choose-list-wrap ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: #FFF;
}

.avt-why-choose-sec .avt-why-choose-list-wrap ul li h2 {
    color: #fff;
    font-family: Inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: 90px;
    /* 214.286% */
    letter-spacing: -1.12px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-align: left;
    border-bottom: 1px solid #eeeeee47;
    text-transform: capitalize;
}


/* home why choose sec end */


/* home portfolio */

.avt-portfolio-sec {
    padding: 80px 0;
}

.avt-portfolio-sec .avt-portfolio-top-row {
    padding-bottom: 40px;
}

.avt-portfolio-sec .avt-portfolio-top-row h2 {
    margin-bottom: 40px;
}

.avt-portfolio-sec .avt-portfolio-top-row a.avt-btn {
    position: relative;
    top: -10px;
}

.avt-portfolio-sec h2 {
    color: #fff;
    font-family: Inter;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 59px;
    /* 98.333% */
}

.avt-portfolio-sec p {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /* 162.5% */
}

.avt-portfolio-sec .text-right {}

.avt-portfolio-sec .text-right a.avt-btn {
    width: fit-content;
    margin: 0 0 0 auto;
}

.avt-portfolio-box {
    position: relative;
}


/* .avt-portfolio-box .avt-content-wrap {
    position: absolute;
    top: 0;
    padding: 20px;
    z-index: 10;
} */

.avt-portfolio-box .avt-portfolio-hover-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 20px;
    z-index: 10;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.5s;
}

.avt-portfolio-box .avt-portfolio-hover-btn:hover {
    opacity: 1;
    transform: scale(1.1);
    background-color: #0000005e;
}


/* .avt-portfolio-box .avt-portfolio-hover-btn:hover img {
    transform: scale(1.1);
} */

.avt-portfolio-box .avt-portfolio-hover-btn img {
    width: 80px;
    height: 80px;
}

.avt-portfolio-box {
    overflow: hidden;
}

.avt-portfolio-box .avt-img-wrap {
    transition: all 0.5s;
}

.avt-portfolio-box .avt-img-wrap:hover {
    transform: scale(1.1);
}

.avt-portfolio-box .avt-img-wrap img {
    width: auto;
    max-width: fit-content;
}

.avt-portfolio-box .avt-img-wrap {
    position: relative;
}

.avt-portfolio-box .avt-img-wrap::before {
    content: '';
    /* background-color: #00000087; */
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}


/* home portfolio end */


/* testimonials sec */

.avt-testimonials-sec {
    background-color: #FFF;
    /* background-color: #eee; */
    padding: 120px 0;
    /* height: 100vh; */
    color: #000;
    min-height: 820px;
    display: flex;
    align-items: center;
    transition: all 1s;
}

.avt-testimonials-sec .carousel .carousel-inner {
    height: 550px;
    align-items: center;
    display: flex;
}

.avt-testimonials-sec .carousel-indicators [data-bs-target] {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: white;
    border: 2px solid #BE1E2D;
    opacity: 0.7;
}

.avt-testimonials-sec .carousel-indicators button[aria-current="true"] {
    background-color: #BE1E2D;
    border: 2px solid #BE1E2D;
    opacity: 1;
}

.avt-testimonials-sec .avt-testimonial-img-wrap {
    background-color: #eee;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avt-testimonials-sec .avt-testimonial-img-wrap .avt-testimonail-img {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 150px;
}

.avt-testimonials-sec .carousel-indicators {
    /* margin-left: -68px;
    bottom: -25px; */
    bottom: -25px;
    width: fit-content;
    left: 242px;
}

.avt-testimonials-sec .row {
    align-items: center;
}

.avt-testimonials-sec .avt-testimonials-main-wrap h6 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
}

.avt-testimonials-sec .avt-testimonials-main-wrap h4 {
    color: #000;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
    /* 134.783% */
}

.avt-testimonials-sec .col-left-tesi-wrap {
    text-align: center;
    display: flex;
    justify-content: center;
}

.avt-testimonials-sec .avt-testimonials-designation {
    padding: 40px 0;
}

.avt-testimonials-sec .carousel-control-prev,
.avt-testimonials-sec .carousel-control-next {
    display: none !important;
}

.avt-testimonials-sec .avt-testimonials-designation h6 {
    color: #000;
    background: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0px;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    /* 137.222% */
}

.avt-testimonials-sec .avt-testimonials-designation p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.7px;
    /* 164.667% */
}


/* testimonials sec end */


/* about page */

.avt-about-banner {
    height: 1000px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 80px;
}

.avt-about-banner h6 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avt-about-banner .avt-content-wrap h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 86px;
    font-style: normal;
    font-weight: 300;
    line-height: 110px;
    /* 127.907% */
    letter-spacing: -1.72px;
    margin-top: -20px;
    margin-bottom: 40px;
}

.avt-about-banner .avt-content-wrap h1 span {
    color: #FFF;
    font-family: Inter;
    font-size: 86px;
    /* font-style: italic; */
    /* font-weight: 100; */
    line-height: 110px;
    letter-spacing: -1.72px;
}


/* about sec */

.avt-about-sec {
    height: 100%;
    min-height: 100vh;
    background-image: url(../public/assets/images/about/about-bg-img.png);
    background-size: cover;
    padding: 40px 0;
    position: relative;
    display: flex;
    align-items: center;
}

.avt-about-sec .row {
    align-items: center;
}

.avt-about-sec .avt-content-wrap {
    position: relative;
    z-index: 10;
}


/* .avt-about-sec .row .avt-left-col {
     position: relative;
 } */

.avt-about-sec .row .avt-left-col .avt-content-wrap {
    position: absolute;
    z-index: 10;
    bottom: 60px;
    left: 185px;
}

.avt-about-sec .row .avt-left-col .avt-content-wrap h6 {
    color: #BE1E2D;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
}

.avt-about-sec .row .avt-left-col .avt-content-wrap h2 {
    color: #FFF;
    font-family: Inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.12px;
}

.avt-about-sec .row .avt-left-col .avt-content-wrap p {}

.avt-about-sec .avt-about-img-wrap {
    position: absolute;
    bottom: 0;
}

.avt-about-sec::before {
    content: '';
    position: absolute;
    background-image: url(../public/assets/images/about/bg-about-img.png);
    /* background-size: 47%; */
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    width: 50%;
    height: 100%;
    z-index: 0;
    left: 160px;
    top: 0px;
    opacity: 0.4;
    transition: all 2.5s;
    -webkit-animation: rotation 8s infinite linear;
}

.avt-about-sec:hover::before {
    -webkit-transform: rotate(-30deg);
    top: -5px;
}


/* @-webkit-keyframes rotation {
     from {
         -webkit-transform: rotate(0deg);
     }
     to {
         -webkit-transform: rotate(359deg);
     }
 } */

.avt-about-sec .avt-about-img-wrap img {
    width: 90%;
    margin: 0 auto;
    display: block;
    z-index: 10;
    position: relative;
}

.avt-about-sec .row {
    padding: 0 100px;
}

.avt-about-sec .row .avt-right-col .avt-content-wrap h6 {
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    /* 159.091% */
    letter-spacing: -0.44px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateX(-80px);
    margin-bottom: 40px;
}

.avt-about-sec .row .avt-right-col .avt-content-wrap p {}


/* about sec end */


/* process sec */

.avt-about-process-sec {
    background-color: #fff;
    padding: 80px 0;
}

.process-tabs-wrap h2 {
    cursor: pointer;
}

.avt-process-img-wrap div {
    display: none;
}

.avt-process-img-wrap .active {
    display: block;
}

.avt-about-process-sec .row {
    align-items: center;
}

.avt-about-process-sec .row .col-lg-12 {}

.avt-about-process-sec h6 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
}

.avt-about-process-sec h2 {
    color: #E1E1E1;
    font-family: Inter;
    font-size: 120px;
    font-style: normal;
    font-weight: 900;
    line-height: 134px;
    /* 97.727% */
    letter-spacing: -2.64px;
    transition: all 0.5s;
}

.avt-about-process-sec h2.active {
    color: #BE1E2D;
}

.avt-process-img-wrap img {
    width: auto;
}


/* process sec */


/* values sec */

.avt-values-sec {
    background-color: #fff;
    padding: 100px 0 280px 0;
    position: relative;
}

.avt-values-sec .avt-values-sec-wrap {
    position: relative;
    z-index: 999;
    top: -100px;
}

.avt-values-sec-wrap .avt-btn {
    margin: 0 auto;
    width: auto;
}

.avt-values-sec-wrap .avt-btn span {
    color: #000;
}

.avt-values-sec-wrap .avt-btn span {
    left: 20px;
}

.avt-contact-sec .avt-btn span {
    left: 25px;
}

.avt-value-main-heading {
    color: #000;
}


/* .avt-value-main-heading::before {
    content: 'Our Values';
    position: absolute;
    bottom: 0px;
    font-size: 190px;
    left: -35px;
    display: flex;
    opacity: 0.1;
    width: 100%;
    justify-content: left;
    font-weight: 800;
    z-index: 1;
    font-family: Inter;
    font-size: 376px;
    font-style: normal;
    font-weight: 700;
    line-height: 335px;
    letter-spacing: -7.52px;
    text-transform: uppercase;
    opacity: 0.08;
} */

.avt-values-sec .avt-values-inner-wrap {
    margin-bottom: 60px;
}

.avt-values-sec .avt-values-inner-wrap img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.avt-values-sec .avt-values-inner-wrap h3 {
    color: #000;
    margin: 20px 0 17px 0;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}

.avt-values-sec .avt-values-inner-wrap p {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.avt-about-why-work-sec {
    padding: 100px 0;
    background-color: #000;
    /* margin: 100px 0 0 0; */
}

.avt-about-why-work-sec .avt-values-inner-wrap h3 {
    color: #fff;
}

.avt-about-why-work-sec .avt-values-inner-wrap p {
    color: #fff;
}

.avt-about-why-work-sec .avt-value-main-heading::before {
    color: #fff;
}


/* values sec end */


/* services */

.avt-services-banner {
    /* height: 1000px; */
    min-height: 600px;
    height: ;
    padding-top: 220px;
    align-items: start;
}

.avt-services-banner.avt-about-banner h1 {
    font-size: 60px;
    font-weight: 300;
    line-height: 85px
}

.avt-services-banner.avt-about-banner h1 span {
    font-size: 60px;
    line-height: 85px
}

.avt-our-services-sec {
    background-color: #fff;
    padding: 0px 0 60px 0;
}

.avt-our-services-sec .avt-full-container-img {
    /* position: relative;
     top: -350px; */
}

.avt-service-whywork-sec {
    background-repeat: no-repeat;
    background-position: center left;
    background-attachment: fixed;
}

.avt-service-web-sec {
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.avt-about-values-sec-dark {
    background-repeat: no-repeat;
    background-position: bottom left;
    background-attachment: fixed;
}

.avt-about-whywork-sec {
    background-repeat: no-repeat;
    background-position: center left;
    background-attachment: fixed;
}

.container.avt-full-container-img {
    width: calc(((100% - 1040px) / 2) + 1170px);
    max-width: calc(((100% - 1040px) / 2) + 1170px);
    margin-right: 0;
    padding: 0;
    margin-bottom: 80px;
}

.avt-our-services-sec .avt-full-container-img img {
    width: 100%;
    margin-top: -330px;
}

.avt-our-services-sec h2 {
    color: #C6C6C6;
    text-align: left;
    font-family: Inter;
    font-size: 82px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -2.46px;
}

.avt-our-services-sec .avt-btn {
    color: #000;
    display: flex;
    justify-content: center;
    width: fit-content;
}

.avt-accordion-wrap {
    margin: 40px 0 70px 0;
}


/* .accordion-header {
    border-bottom: 1px solid #eee;
} */

.avt-expertise-wrap .accordion-button {
    color: #565656 !important;
    background-color: transparent !important;
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.84px;
    border: none !important;
    padding-left: 0px;
    padding-right: 0px;
}

.avt-expertise-wrap .accordion {
    padding: 10px 0;
    --bs-accordion-border-width: none !important;
}

.avt-expertise-wrap .accordion-body {
    padding: 0 5px;
}

.avt-expertise-wrap .accordion-button:focus {
    outline: 0;
    border: none;
    box-shadow: none;
}

.avt-expertise-wrap .avt-btn {
    margin-top: 105px;
    margin-bottom: 60px;
}

.accordion-item {
    border: none !important;
    border-bottom: 1px solid #D8D8D8 !important;
    padding: 15px 0;
}

.avt-accordion-list-wrap {
    /* padding: 20px 0; */
    /* border-bottom: 1px solid #D8D8D8; */
    position: relative;
}

.avt-accordion-list-wrap:nth-child(1):hover {
    border-color: transparent;
}

.avt-accordion-icon {
    position: absolute;
    top: 22%;
    right: 0;
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

.avt-accordion-icon::before {
    content: '+';
    color: #000;
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    top: 22%;
    right: 0;
}

.avt-accordion-list-wrap:hover .avt-accordion-icon::before {
    content: '-';
}

.accordion-button::after {
    content: '+' !important;
    background-image: none !important;
    color: #000;
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    top: 22%;
    right: 0;
    transform: none !important;
}

.accordion-button:not(.collapsed)::after {
    content: '-' !important;
    background-image: none !important;
    transform: none !important;
}

.avt-accordion-list-wrap .avt-accordion-title h3 {
    color: #565656;
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.84px;
    text-align: left;
    transition: all 0.5s;
}

.avt-accordion-list-wrap .avt-accordion-title h3:hover {
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* .avt-accordion-list-wrap .avt-accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.6s ease-in-out;
    opacity: 0;
}

.avt-accordion-list-wrap:hover .avt-accordion-content {
    max-height: 1000px;
    opacity: 1;
} */

.avt-accordion-list-wrap .avt-accordion-content p {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: -0.32px;
    text-align: left;
    margin-bottom: 0px;
}

.avt-why-work-sec {
    padding: 180px 0;
}

.avt-why-work-sec .avt-value-main-heading::before {
    font-size: 300px;
    line-height: 295px;
}


/* ** */

.avt-web-sec {
    padding: 180px 0;
}

.avt-web-sec .avt-value-main-heading::before {
    font-family: Inter;
    font-size: 326px;
    font-style: normal;
    font-weight: 700;
    line-height: 295px;
    /* 90.491% */
    letter-spacing: -6.52px;
    text-transform: uppercase;
    bottom: auto;
    left: 140px;
}

.avt-web-sec h4 {
    color: #000;
    font-family: Inter;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 59px;
    /* 113.462% */
    letter-spacing: -1.04px;
    margin-bottom: 40px;
}


/* ** */

.avt-service-process-sec {
    background-color: #000;
    padding: 160px 0 0 0;
}

.avt-service-process-sec .avt-process-img-wrap {
    text-align: right;
}

.avt-service-process-sec .avt-process-img-wrap img {
    width: 500px;
}


/* services end */


/* contact sec */

.avt-contact-sec {
    padding: 180px 0;
}

.avt-contact-sec h2 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.col-contact-left-inner-wrap {
    position: relative;
    top: 36px;
}

.avt-contact-form-wrap input,
.avt-contact-form-wrap textarea {
    background-color: transparent;
    color: fff;
    border: none;
    border-bottom: 1px solid #212121;
    margin: 10px 0 15px 0;
    padding: 15px 0;
    color: #5C5C5C;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    transition: all 0.5s;
}

.avt-contact-form-wrap input:focus {
    color: #fff;
    outline: 0;
    border-bottom: 1px solid #FFF;
    opacity: 1;
    background: radial-gradient(106.33% 106.33% at 50% 135%, #B51A40 0%, rgba(0, 0, 0, 0.00) 73.58%);
}

.avt-contact-form-wrap textarea:focus {
    color: #fff;
    outline: 0;
    border-bottom: 1px solid #FFF;
    opacity: 1;
    background: radial-gradient(106.33% 106.33% at 50% 152%, #B51A40 0%, rgba(0, 0, 0, 0.00) 73.58%);
}

.avt-contact-form-wrap .avt-submit-wrap {
    margin: 15px 0 10px 0;
}

.avt-custom-model-v2-wrap .avt-popup-contact-wrap {
    padding: 0;
}

.avt-custom-model-v2-wrap .avt-popup-contact-wrap .container-fluid {
    padding: 0;
}

.modal-body {
    padding: 0 !important;
}

.modal-dialog {
    border-radius: 30px !important;
    overflow: hidden !important;
}

.avt-popup2-contact-wrap .avt-contact-sec {
    padding: 0;
}

.avt-popup2-contact-wrap.avt-popup-contact-wrap {
    padding: 0;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 2px 10px #53535330 !important;
    /* border: 1px solid #ffffff0f !important; */
}

.avt-popup2-contact-wrap.avt-popup-contact-wrap .container-fluid {
    padding: 0;
}

.avt-popup2-contact-wrap .avt-contact-form-wrap {
    padding: 30px 140px 35px 140px;
}

.avt-popup2-contact-wrap .avt-form-sent {
    font-size: 14px;
    background-color: #5e9d5e;
}

.avt-popup2-contact-wrap .avt-popup-form-wrap .avt-popup-form-heading-cls {
    color: #fff;
    font-weight: 600;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    -webkit-background-clip: unset;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    padding: 45px 20px 40px 20px;
    font-size: 45px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 0px;
}

.avt-popup2-contact-wrap .avt-input-wrap input,
.avt-popup2-contact-wrap .avt-input-wrap textarea {
    color: #000;
    font-size: 22px;
    padding: 10px;
}

.avt-popup2-contact-wrap .avt-submit-wrap button.avt-btn {
    color: #000;
}

.avt-contact-form-light-ver-wrap {}

.avt-submit-wrap button.avt-btn {
    background-color: transparent;
    border: none;
}

.avt-contact-list-wrap {
    list-style-type: none;
    padding: 0;
    margin: 60px 0 0 0;
}

.avt-contact-list-wrap li {
    margin-bottom: 20px;
}

.avt-contact-list-wrap .avt-contact-list-content {
    margin: 20px 0;
    line-height: 30px;
}

.avt-contact-list-wrap .avt-contact-list-content,
.avt-contact-list-wrap .avt-contact-list-content a {
    display: block;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8em;
    letter-spacing: -0.32px;
    text-decoration: none;
    transition: all 0.5s;
}

.avt-contact-list-wrap .avt-contact-list-content a:hover {
    opacity: 0.7;
}

.avt-checkbox-wrap {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 20px;
    padding: 20px 0;
}

.avt-checkbox-wrap .avt-check-box {
    background-color: #ffffff14;
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 10px;
}

.avt-checkbox-wrap .avt-check-box [type="checkbox"] {
    padding: 0;
    text-align: right;
    margin: 0 0 0 20px;
    width: 30px;
}

.avt-checkbox-wrap .avt-check-box span {
    color: #fff;
}

.avt-form-sent {
    background-color: #8ade8a6e;
    color: #fff;
    padding: 15px 40px;
    border-radius: 6px;
    margin: 20px 0;
    width: fit-content;
}

.avt-form-failed {
    background-color: #ff000073;
    color: #fff;
    padding: 15px 40px;
    border-radius: 6px;
    margin: 20px 0;
    width: fit-content;
}


/* contact page */


/* contact sec end */


/* blog css */

.avt-blog-banner-sec {
    background-color: #000;
    color: #fff;
    height: 500px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 60px;
}

.avt-blog-banner-sec h2 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avt-blog-title a {
    text-decoration: none;
    color: #000;
}

.avt-blog-banner-sec h1 {
    color: #fff;
    font-family: Inter;
    font-size: 66px;
    font-style: normal;
    font-weight: 300;
    line-height: 90px;
    /* 136.364% */
    letter-spacing: -1.32px;
    margin-top: -20px;
}

.avt-blog-sec {
    background-color: #fff;
    color: #000;
}

.avt-case-study-sec {
    padding: 0px 0 40px 0;
}

.avt-case-study-sec .avt-blog-content-wrap h2 {
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.44px;
    margin-bottom: 15px;
}

.avt-case-study-sec .avt-blog-content-wrap h2 a {
    color: #000;
    text-decoration: none;
}

.avt-case-study-sec .avt-blog-content-wrap p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 140% */
    letter-spacing: -0.3px;
}


/* ** */

.avt-blog-detail-banner-sec h1 {
    padding-bottom: 60px;
}

.avt-blogs-repeater-row.row {
    margin-top: -100px;
}

.avt-blog-img-wrap img {
    max-width: 100%;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    object-fit: cover;
    background-color: #efefef;
}

.avt-blog-content-wrap .read-more {
    display: none;
}

.avt-pagination-wrap {
    padding: 40px 0;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    transition: all 3s;
}

.avt-pagination-wrap button {
    background-color: transparent;
    border: none;
    color: #000;
    border-radius: 100px;
    width: 35px;
    height: 35px;
}

.avt-pagination-wrap .active {
    background-color: #B51A40;
    color: #fff;
    margin: 0 5px;
}

.avt-blog-wrap {
    padding: 10px 0;
}

.avt-blog-content-wrap {
    padding: 10px 0;
    margin-bottom: 20px;
}

.avt-blog-content-wrap h2 {
    color: #000;
    margin: 10px 0 20px 0;
}

.avt-blog-content-wrap p {
    color: #000;
}

.avt-blog-content-wrap a.avt-btn,
.avt-blog-content-wrap a.avt-btn span {
    color: #000;
    margin: 10px 0;
}


/* blog single */

.avt-blog-detail-sec {}

.avt-blog-detail-sec .avt-blog-wrap img {
    width: 100%;
    max-width: 100%;
}

.avt-blog-detail-sec .avt-blog-content-wrap p {
    color: #6F7F92;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.56px;
    margin: 15px 0;
    /* 166% */
}

.avt-blog-detail-sec .avt-blog-content-wrap h2 {
    color: #000;
    /* download (43)/Inter/Black 35.68 title */
    font-family: Inter;
    font-size: 35.679px;
    font-style: normal;
    font-weight: 900;
    line-height: 49.28px;
    /* 138.119% */
    text-transform: capitalize;
    margin: 25px 0;
}

.avt-sidebar {
    padding: 40px 0;
    margin-left: 40px;
}

.avt-sidebar ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.avt-sidebar h2 {
    color: #000;
    /* download (43)/Inter/Semi Bold 22.88 title */
    font-family: Inter;
    font-size: 22.875px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.2px;
    /* 136.393% */
    text-transform: capitalize;
    margin-bottom: 20px;
}

.avt-sidebar ul li h3 {
    color: #BE1E2D;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 175% */
    text-transform: capitalize;
}

.avt-sidebar ul li h3 a {
    text-decoration: none;
    color: #BE1E2D;
    margin-bottom: 20px;
}


/* blog single end */


/* case study */

.avt-case-main-banner-sec-v1 {
    height: 600px;
}

.avt-case-main-banner-sec-v1 .avt-content-wrap h1 {
    margin-bottom: 25px;
}

.avt-case-main-banner-sec-v2 {
    height: 100vh;
    background-color: #fff;
    text-align: center;
}

.avt-case-main-banner-sec-v2 img {
    width: 800px;
    margin-top: 100px;
}

.avt-banner-img-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avt-case-main-banner-sec-v2 {}

.avt-case-study-below-banner-row-v2 {
    padding: 100px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 60px;
}

.avt-case-study-below-banner-row-v2 .avt-left-col {
    position: relative;
    top: -10px;
}

.avt-case-study-below-banner-row-v2 .avt-left-col h6 {
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avt-case-study-below-banner-row-v2 .avt-right-col h4 {
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.44px;
    margin-bottom: 15px;
}

.avt-case-study-below-banner-row-v2 .avt-right-col p {
    color: #939393;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.44px;
    margin-bottom: 45px;
}

.avt-case-study-below-banner-sec .avt-full-container-img img {
    width: 100%;
    margin-top: -100px;
}

.avt-case-study-below-banner-row {
    padding: 40px 0;
}

.avt-services-banner-list-wrap ul {
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0 0;
}

.avt-services-banner-list-wrap ul li {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 160% */
    letter-spacing: -0.3px;
    color: #fff;
}

.avt-our-services-sec {
    color: #000;
}

.avt-our-services-sec p {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* 138.889% */
    letter-spacing: -0.18px;
}

.avt-case-study-banner-sec {
    /* background-image: url(../public/assets/images/case-study-detail/case-study-banner-img.png); */
    /* background-attachment: fixed;
    background-position: center center;
    background-size: cover; */
    padding: 0;
    margin: 0;
    /* height: 1100px; */
    background-color: #fff;
}

.avt-case-study-banner-sec .row .col-md-12 {
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0px 0px 5px #00000040;
    padding: 0;
}

.avt-case-study-banner-sec img {
    overflow: hidden;
    /* opacity: 0; */
    transform: translateY(0px);
    transition: all 1s;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 5px #00000040;
}

.avt-case-study-banner-sec .col-md-12:hover img {
    transform: scale(1.03);
    overflow: hidden;
}

.avt-case-study-quote-sec {
    padding: 120px 80px 20px 80px;
    background-color: #fff;
    text-align: center;
}

.avt-case-study-quote-sec h3 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
    /* 134.783% */
    margin-bottom: 50px;
}

.avt-case-study-quote-sec h5 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.7px;
    /* 137.222% */
}

.avt-case-study-quote-sec h6 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.7px;
    /* 164.667% */
}

.avt-case-study-nav-sec {
    background-color: #fff;
    padding: 110px 0 120px 0;
}

.avt-case-study-nav-sec h6 {
    color: #ADADAD;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.avt-case-study-nav-sec h3 {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 0.5s;
}

.avt-case-study-nav-sec a {
    text-decoration: none;
}

.avt-case-study-nav-sec h3:hover {
    color: #BE1E2D;
}

.avt-case-study-nav-sec:hover h6 {
    opacity: 0.7;
}

.avt-case-study-nav-sec .avt-case-study-nav-row {
    padding: 0 300px;
}

.avt-case-study-nav-sec .avt-case-study-nav-row .avt-prev-nav {
    border-right: 1px solid #eee;
}

.avt-case-study-nav-sec .avt-case-study-nav-row .avt-next-nav {
    text-align: right;
}


/* case study end */


/* *************************************** */


/* footer sec */

.avt-footer-sec {
    padding: 140px 0 40px 0;
    color: #fff;
}

.avt-footer-icons-list ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    column-gap: 20px;
    margin: 20px auto 50px auto;
}

.avt-footer-sec .avt-btn {
    position: relative;
    top: -50px;
}

.avt-footer-menu ul li a:hover {
    color: #BE1E2D;
}

.avt-footer-icons-list ul li a img:hover {
    opacity: 0.6;
}


/* popup */

.modal.show {
    overflow: hidden;
}

.modal-header {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none !important;
    box-shadow: none;
}

.modal-header .btn-close {
    color: #fefefe !important;
    opacity: 1;
    border-radius: 100px;
    background-color: #000;
    font-size: 18px;
    padding: 14px !important;
    border: 1px solid #eeeeee3f;
    position: absolute;
    right: 30px;
    top: 60px;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    --bs-btn-close-bg: none;
    background-image: none;
}

.modal-header .btn-close::after {
    content: 'x';
    font-size: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    transform: translateY(5px);
    font-family: cursive;
}

.modal-header .btn-close {
    margin-top: 10px;
    margin-right: 10px;
}

.modal-header .btn-close:hover {
    opacity: 0.7;
}

.modal-dialog {
    max-width: 1050px !important;
    display: flex;
    align-items: center;
    height: 100%;
}

.modal-content {
    /* background-color: #000 !important; */
    background-color: transparent !important;
    width: 1050px !important;
    margin: 0 auto !important;
    border: none !important;
}

.avt-popup-contact-wrap {
    background-color: #000;
    padding: 20px 45px 40px 45px;
}


/* popup end */


/* error page */

.avt-error-sec {
    height: 100vh;
    background-color: #000;
    color: #fff;
}

.avt-error-sec .avt-error-cls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.avt-error-sec .avt-error-cls h1 {
    color: #fff;
    font-family: Inter;
    font-size: 66px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: -1.32px;
    margin-bottom: 15px;
}

.avt-error-sec .avt-error-cls p {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}


/* error page end */

.avt-footer-sec .row {}

.avt-footer-sec .avt-footer-logo-wrap {
    position: relative;
    background-attachment: fixed;
}

.avt-footer-sec .avt-footer-logo-wrap {}

.avt-footer-sec .avt-footer-logo-wrap h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-family: Inter;
    font-size: 102px;
    font-style: normal;
    font-weight: 900;
    line-height: 100px;
    /* 98.039% */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avt-footer-btn-wrap {
    padding: 40px 0;
}

.avt-footer-col {
    margin: 0 auto;
}

.avt-footer-menu {
    padding: 30px 0;
}

.avt-footer-menu ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    column-gap: 30px;
    justify-content: center;
}

.avt-footer-menu ul li a {
    text-decoration: none;
    color: #FFF;
}


/* footer sec end */

.container {}


/* CustomCursor.css */

.custom-cursor {
    position: fixed;
    width: 30px;
    height: 30px;
    background-color: #ffffff71;
    border-radius: 50%;
    pointer-events: none;
    z-index: 999;
    transition: background-color 0.3s;
}

.custom-cursor.hovered {
    background-color: #fff;
    mix-blend-mode: difference;
}

.avt-portfolio-sec .text-right {
    padding-right: 60px;
}

.avt-contact-banner-v2-sec {
    background-color: #000;
    color: #fff;
    height: 600px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 40px;
    background-image: url('../public/assets/images/contact/v2/contact banner.png');
    background-size: 70%;
    background-position: top center;
    background-repeat: no-repeat;
}

.avt-contact-banner-v2-form-sec {
    background-color: #fff;
    height: 700px;
}

.avt-contact-banner-v2-form-sec .avt-btn span {
    color: #000;
}

.avt-contact-banner-v2-form-sec .avt-btn input {
    height: 200px;
}

.avt-contact-banner-v2-form-sec .avt-form-wrap-v2 {
    background-color: #fff;
    /* box-shadow: 2px 2px 5px #000; */
    padding: 40px 90px;
    margin-top: -160px;
    border-radius: 15px;
}

.avt-contact-banner-v2-sec h6 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.avt-contact-banner-v2-sec h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 66px;
    font-style: normal;
    font-weight: 300;
    /* line-height: 90px; */
    /* 136.364% */
    letter-spacing: -1.32px;
    margin-bottom: 10px;
    margin-top: -13px;
}

.avt-contact-banner-v2-sec .contact-list-wrap {
    padding: 60px 0 0 0;
}

.avt-contact-banner-v2-sec .contact-list-wrap ul {
    display: flex;
    column-gap: 100px;
    list-style-type: none;
}

.avt-contact-banner-v2-sec .contact-list-wrap ul li a {
    color: #fff;
    text-decoration: none;
    text-align: right;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.avt-contact-banner-v2-sec .contact-list-wrap ul li a {
    position: relative;
}

.avt-contact-banner-v2-sec .contact-list-wrap ul li a span {
    position: absolute;
    top: -35px;
    color: #BE1E2D;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.avt-contact-banner-v2-sec .contact-list-wrap ul li a img {
    margin-right: 20px;
}

.my-custom-class {
    color: #fff;
}

.my-animate-wrap {
    padding: relative;
}

.avt-contact-banner-v2-form-sec .avt-contact-form-wrap input,
.avt-contact-banner-v2-form-sec .avt-contact-form-wrap textarea {
    border-bottom: 1px solid #D8D8D8;
    color: #BEBEBE;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.avt-contact-banner-v2-form-sec .avt-contact-form-wrap input:focus,
.avt-contact-banner-v2-form-sec .avt-contact-form-wrap textarea:focus {
    background-color: #fff;
    border-bottom: 1px solid #6e6b6b;
    background: none;
    color: #000;
}

.avt-contact-form-wrap textarea {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}

.avt-contact-banner-v2-form-sec .avt-form-sent {
    background-color: #549454;
    margin-top: 40px;
}

.avt-case-study-178 {
    display: none;
}


/* .avt-contact-form-wrap textarea:focus {
    font-size: 15px;
} */


/* .cc-cls {
    height: 1500px;
} */


/* *********************** */

.avt-blog-banner-sec {
    background-color: #000;
    color: #fff;
    height: 500px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 60px;
}

.avt-blog-banner-sec h2 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* term page */

.avt-term-banner-sec {
    background-color: #000;
    color: #fff;
    height: 380px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 60px;
}

.avt-term-banner-sec h2 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    background: linear-gradient(240deg, #EC008C 7.02%, #BE1E2D 87.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: 100px;
}

.avt-term-content-sec {
    background-color: #fff;
}

.avt-term-content-sec .avt-content-wrap {
    background-color: #fff;
    color: #000;
    font-size: 18px;
    padding: 55px 70px;
    border-radius: 15px;
    margin-top: -205px;
    margin-bottom: 60px;
}

.avt-term-content-sec .avt-content-wrap p {
    color: #000;
    font-size: 18px;
}


/* term page end */


/* media */

@media (min-width: 992px) {
    /* lenis */
    html.lenis {
        height: auto;
    }
    .lenis.lenis-smooth {
        scroll-behavior: auto;
    }
    .lenis.lenis-smooth [data-lenis-prevent] {
        overscroll-behavior: contain;
    }
    .lenis.lenis-stopped {
        overflow: hidden;
    }
    .lenis.lenis-scrolling iframe {
        pointer-events: none;
    }
}

@media (max-width: 1600px) {
    .avt-header-wrap .row.col-header-row {
        padding: 0 0px;
    }
    .avt-home-about-img-wrap img {
        width: 800px;
    }
    .avt-home-about-sec {
        padding-bottom: 40px;
        margin-bottom: 80px;
    }
}


/* *********************** */

@media (min-width:1800px) {
    /* .container {
         max-width: 1600px;
     } */
}

@media (max-width: 1680px) {
    .avt-home-about-img-wrap {
        left: -80px;
        width: 900px !important;
    }
    .avt-home-brands-sec .row h2 {
        font-size: 80px;
    }
    .avt-home-about-sec .avt-content-sec {
        position: relative;
        left: 40px;
    }
    .avt-value-main-heading::before {
        font-size: 350px;
        bottom: -40px;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        font-size: 260px;
    }
    .avt-about-process-sec h2 {
        font-size: 100px;
    }
    .avt-about-sec .avt-about-img-wrap img {
        width: 80%;
        left: 120px;
    }
    .avt-home-brands-sec .row p {
        font-size: 20px;
    }
    .avt-home-services-sec {
        padding-top: 140px;
    }
    .avt-about-process-sec h2 {
        line-height: 1.2em;
    }
}

@media (max-width: 1440px) {
    .avt-case-study-quote-sec {
        padding: 140px 200px 20px 200px;
    }
    .avt-case-study-nav-sec .avt-case-study-nav-row {
        padding: 0px 100px;
    }
    .avt-home-brands-sec .row h2 {
        font-size: 70px;
    }
    .avt-home-about-img-wrap {
        left: 80px;
        width: 600px !important;
        margin: 0 auto;
        margin-top: 0px;
    }
    .avt-home-services-sec {
        padding-top: 100px;
    }
    .avt-footer-sec .avt-footer-logo-wrap h2 {
        font-size: 90px;
    }
    .avt-about-banner .avt-content-wrap h1 span {
        font-size: 80px;
    }
    .avt-about-sec .row .avt-left-col .avt-content-wrap {
        left: 95px;
    }
    .avt-value-main-heading::before {
        font-size: 280px;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        font-size: 230px;
        line-height: 260px;
    }
    .avt-about-values-sec {
        padding: 100px 0 200px 0;
    }
    .avt-value-main-heading::before {
        font-size: 280px;
        line-height: 1.2em;
    }
    .avt-about-banner .avt-content-wrap h1 span {
        font-size: 70px;
    }
    .avt-blog-banner-sec h1 {
        /* margin-top: 30px; */
        margin-bottom: 40px;
    }
    .avt-web-sec .avt-value-main-heading::before {
        font-size: 240px;
    }
    .avt-web-sec .avt-values-sec-wrap {
        top: 80px !important;
    }
    .avt-popup2-contact-wrap .avt-contact-form-wrap {
        padding: 25px 120px 40px 120px;
        max-height: 380px;
        overflow-y: auto;
        scrollbar-width: thin;
    }
}

@media (max-width: 1366px) {
    .avt-home-about-sec .avt-content-sec {
        left: 120px;
        padding-right: 120px;
    }
    .avt-home-services-sec {
        padding-top: 80px;
    }
    .avt-services-02-img img {
        width: 60%;
    }
    .avt-services-row-02 .avt-home-services-img-wrap {
        top: 70px;
    }
    .avt-services-row-02 p,
    .avt-services-row-02 .avt-btn {
        transform: translateX(60px) !important;
    }
    .avt-about-banner .avt-content-wrap h1 {
        font-size: 80px;
        line-height: 1.2em;
    }
    .avt-about-banner .avt-content-wrap h1 span {
        font-size: 60px;
        line-height: 1.4em;
    }
    .avt-about-sec .avt-about-img-wrap img {
        left: 45px;
    }
    .avt-about-sec .row .avt-right-col .avt-content-wrap h6 {
        transform: translateX(-60px);
    }
    .avt-services-banner.avt-about-banner h1 {
        font-size: 60px;
        line-height: 1.4em;
    }
    .avt-about-banner {
        height: 900px;
    }
    .avt-header-wrap .navbar-collapse a.nav-link {
        padding: 0 9px;
    }
    .avt-header-wrap .navbar-collapse a.nav-link {
        padding: 0 15px;
    }
    .avt-testimonials-sec .carousel-indicators {
        bottom: -25px;
        width: fit-content;
        left: 210px;
    }
    /* *************** */
    .avt-popup2-contact-wrap .avt-popup-form-wrap .avt-popup-form-heading-cls {
        font-size: 35px;
        padding: 40px 20px 35px 20px;
    }
    .avt-popup2-contact-wrap .avt-input-wrap input,
    .avt-popup2-contact-wrap .avt-input-wrap textarea {
        font-size: 20px;
        padding: 7px;
    }
    .avt-popup2-contact-wrap .avt-contact-form-wrap {
        padding: 25px 120px 40px 120px;
        max-height: 380px;
        overflow-y: auto;
        scrollbar-width: thin;
    }
    .avt-popup2-contact-wrap .avt-submit-wrap button.avt-btn {
        font-size: 18px;
    }
}

@media (max-width: 1280px) {
    .avt-about-banner {
        height: 1000px;
    }
    .avt-about-sec .avt-about-img-wrap img {
        left: 80px;
    }
    .avt-about-sec .avt-about-img-wrap img {
        width: 77%;
    }
    .avt-about-sec .row .avt-right-col .avt-content-wrap h6 {
        transform: translateX(-40px);
    }
    .avt-about-banner.avt-services-banner.avt-case-main-banner-sec-v1 {
        height: 900px;
    }
    .avt-services-row-02 p,
    .avt-services-row-02 .avt-btn {
        transform: translateX(0px) !important;
    }
    .avt-top-row {
        padding: 20px 20px;
    }
    .avt-middle-row {
        padding: 40px 0 20px 150px;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link {
        font-size: 35px;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::before {
        margin: 13px 0 0 5px;
        right: 5px;
    }
}


/* @media (max-width: 1152px) {


} */

@media (max-width: 768px) {
    .col-header-row {
        align-items: center;
    }
    .avt-header-social-list {
        display: none;
    }
    /* navbar */
    .avt-header-wrap .navbar-toggler {
        background-color: #fff;
    }
    .row.col-header-row {}
    .row.col-header-row .col-lg-5.col-8 {
        order: 1;
        text-align: left;
        display: flex !important;
        justify-content: start !important;
    }
    .row.col-header-row .col-lg-5.col-8 .navbar-toggler {
        justify-content: end !important;
    }
    .row.col-header-row .col-lg-2.col-4.text-center {
        order: 2;
        text-align: right !important;
    }
    .row.col-header-row .navbar-collapse.collapse,
    .row.col-header-row .navbar-collapse,
    .row.col-header-row .collapse {
        position: fixed;
        top: 90px;
        width: 89%;
        background-color: #fff;
        border: 1px solid #eee;
        padding: 10px;
        border-radius: 5px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .avt-header-wrap .navbar-collapse a.nav-link {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 11px 20px;
        border-bottom: 1px solid #eee;
        text-align: center;
    }
    .avt-header-wrap .dropdown-menu.show {
        margin: 0;
        border: none;
    }
    .avt-header-wrap a.dropdown-item {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    .avt-header-wrap .dropdown-divider {
        border-color: #eee;
    }
    /* home */
    .avt-home-banner {
        height: auto;
        padding: 140px 0 60px 0;
    }
    .avt-home-banner h1 {
        font-size: 60px;
        line-height: 1.2em;
        padding-right: 200px;
        font-weight: 200;
    }
    .avt-home-brands-sec .row h2 {
        font-size: 40px;
        line-height: 1.2em;
    }
    .avt-home-brands-sec {
        padding: 60px 0 20px 0;
    }
    .avt-home-brands-sec .row p {
        width: auto;
        font-size: 16px;
        line-height: 1.8em;
        padding: 10px 100px;
    }
    .avt-home-about-sec {
        padding: 20px 0 20px 0;
    }
    .avt-home-about-img-wrap {
        position: unset;
        margin: 20px 0;
    }
    /* ** */
    [data-aos] {
        opacity: 1 !important;
        transform: none !important;
        transition: none !important;
    }
    /* ** */
    .avt-services-row {
        padding: 20px 5px;
    }
    .avt-home-about-sec .avt-content-sec {
        left: 0;
        padding: 0 5px;
    }
    .avt-home-about-sec {
        margin-bottom: 0px;
    }
    .avt-services-row-01 {
        padding-top: 60px;
        padding-bottom: 180px;
    }
    .avt-services-row-01 h3 {
        transform: unset;
        padding-top: 100px;
    }
    .avt-services-row h3 {
        white-space: normal;
    }
    .avt-services-row h3 {
        white-space: normal;
        font-size: 60px;
        margin: 20px 0 30px 0;
    }
    .avt-services-row h5 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .avt-services-row-01 {
        padding-bottom: 10px;
    }
    .avt-services-row-02 .avt-content-sec h5 {
        transform: unset;
    }
    .avt-services-row-03 h3 {
        transform: unset;
    }
    .avt-why-choose-wrap {
        height: auto;
    }
    .avt-why-choose-wrap h6 {
        margin-bottom: 40px;
    }
    .avt-why-choose-sec .avt-why-choose-list-wrap ul li h2 {
        line-height: 1.2em;
        margin-bottom: 0px;
        font-size: 40px;
        padding: 20px 0;
    }
    .avt-portfolio-box .avt-img-wrap img {
        width: 100%;
        max-width: 100%;
        padding: 0 10px;
    }
    .avt-testimonials-sec {
        padding: 60px 0;
    }
    .avt-footer-sec {
        padding-top: 100px;
    }
    .avt-testimonials-sec .avt-testimonials-main-wrap h6 {
        text-align: center;
        margin-top: 40px;
    }
    .avt-footer-sec .avt-footer-logo-wrap h2 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        font-family: Inter;
        font-size: 60px;
        font-style: normal;
        font-weight: 900;
        line-height: 1.2em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .avt-testimonials-sec .avt-testimonial-img-wrap {
        text-align: center;
        margin-bottom: 20px;
    }
    .avt-testimonials-wrap {
        text-align: center;
    }
    .avt-testimonials-sec .avt-testimonials-main-wrap h4 {
        font-size: 22px;
        line-height: 1.4em;
    }
    /* blog */
    .avt-blog-banner-sec h2 {
        margin-bottom: 20px;
        padding-left: 8px;
    }
    .avt-blog-banner-sec h1 {
        font-size: 60px;
        line-height: 1.2em;
        margin-bottom: 15px;
    }
    .avt-case-main-banner-sec-v1 {
        height: auto;
        padding-top: 140px;
        padding-bottom: 60px;
    }
    .avt-case-study-below-banner-sec .avt-full-container-img img {
        margin-top: 0px;
    }
    .container.avt-full-container-img {
        width: auto;
        max-width: fit-content;
        margin-right: 0;
        padding: 0 30px;
        margin-bottom: 0px;
    }
    .avt-our-services-sec {
        padding-bottom: 0px;
    }
    .avt-case-study-quote-sec {
        padding: 60px 10px 20px 20px;
    }
    .avt-case-study-quote-sec h3 {
        font-size: 40px;
        line-height: 1.4em;
        margin-bottom: 20px;
    }
    .avt-case-study-nav-sec {
        background-color: #fff;
        padding: 20px 0 60px 0;
    }
    .avt-case-study-nav-sec .avt-case-study-nav-row .avt-prev-nav {
        border: none;
        text-align: center;
        margin-bottom: 20px;
    }
    .avt-case-study-nav-sec .avt-case-study-nav-row .avt-next-nav {
        border: none;
        text-align: center;
        margin-bottom: 20px;
    }
    .avt-case-study-nav-sec {
        padding-bottom: 20px;
    }
    .avt-case-main-banner-sec-v2 {
        height: auto;
        padding-top: 100px;
    }
    .avt-case-study-below-banner-row-v2 {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .avt-about-banner h6 {
        margin-bottom: 20px;
    }
    .avt-about-banner {
        height: auto;
        padding-top: 120px;
        padding-bottom: 60px;
    }
    .avt-about-banner .avt-content-wrap h1 {
        font-size: 60px;
        font-weight: 300;
        line-height: 85px;
    }
    .avt-about-banner .avt-content-wrap h1 span {
        font-size: 60px;
        font-weight: 300;
        line-height: 85px;
        margin-top: 15px;
    }
    .avt-about-sec {
        height: auto;
        max-height: fit-content;
        min-height: fit-content;
    }
    .avt-about-sec .avt-about-img-wrap img {
        width: 200px;
        filter: drop-shadow(0 6mm 4mm rgb(78, 2, 2));
        border-bottom: 1px solid #000;
        left: 0;
    }
    .avt-about-sec .avt-about-img-wrap {
        position: unset;
    }
    .avt-about-sec .avt-content-wrap {
        position: relative;
        z-index: 10;
        margin-top: 60px;
        /* box-shadow: 0px 7px 2px #80121221; */
    }
    .avt-about-sec .row .avt-left-col .avt-content-wrap {
        position: unset;
    }
    .avt-our-services-sec .avt-full-container-img img {
        margin: 60px 0 40px 0;
    }
    .avt-service-process-sec .avt-process-img-wrap {
        text-align: center;
        padding: 20px 0;
    }
    .avt-contact-sec h2 {
        margin-bottom: 40px;
    }
    .avt-about-process-sec h2 {
        font-size: 60px;
        line-height: 1.4em;
    }
    .avt-about-process-sec h2.active {
        font-size: 60px;
        line-height: 1.4em;
    }
    .avt-contact-sec {
        padding: 40px 0;
    }
    .avt-why-work-sec {
        padding: 60px 0;
    }
    .avt-web-sec {
        padding: 60px 0;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        content: 'Why \A Work \A with Us';
        white-space: pre;
        font-size: 160px;
        line-height: 1.0em;
    }
    .avt-web-sec .avt-value-main-heading::before {
        font-size: 160px;
        line-height: 1.0em;
    }
    .avt-value-main-heading::before {
        font-size: 160px;
        line-height: 1.0em;
    }
    .avt-values-sec {
        padding: 60px 0;
    }
    .avt-about-sec .row {
        padding: 0;
    }
    .avt-about-sec .row .avt-right-col .avt-content-wrap h6 {
        transform: unset;
    }
    .avt-about-sec .avt-content-wrap {
        margin-top: 20px;
    }
    /* **** */
    .custom-cursor {
        display: none !important;
    }
    .avt-case-main-banner-sec-v2 img {
        width: 100%;
        padding: 0 10px;
    }
    .col-header-row .navbar {
        display: flex !important;
        justify-content: end !important;
    }
    .col-header-row .col-lg-5.col-8 {
        display: flex !important;
        justify-content: end !important;
    }
    .avt-header-wrap .row.col-header-row {
        align-items: baseline;
    }
    .avt-testimonials-sec .carousel-indicators {
        margin: 0 auto;
    }
    .avt-services-row-02 .avt-row-2-col-1 {
        order: 2 !important;
    }
    .avt-services-row-04 .avt-row-4-col-1 {
        order: 2 !important;
    }
    .avt-services-row-01 .avt-home-services-img-wrap {
        text-align: center;
    }
    .avt-home-service-2-bg-img {
        display: none;
    }
    .avt-services-02-img img {
        width: 97%;
    }
    .avt-services-02-img {
        top: 138px;
    }
    .avt-contact-banner-v2-sec {
        height: 800px;
        padding-top: 20px;
    }
    .avt-about-sec::before {
        content: '';
        background-size: 72%;
        background-repeat: no-repeat;
        background-position: center 50px;
        width: 100%;
        height: 100%;
        z-index: 0;
        left: 12px;
        opacity: 0.4;
        transition: all 1.8s;
        -webkit-animation: none;
        top: -92px;
    }
    .avt-about-sec:hover::before {
        -webkit-transform: rotate(0deg);
        top: -92px;
    }
    .avt-service-5-back-img {
        display: none;
    }
    .avt-services-row-05 .avt-home-services-img-wrap img {
        transform: unset;
    }
    .avt-services-row-05 .avt-home-services-img-wrap .avt-service-5-front-img:hover {
        transform: unset;
        -webkit-animation: none;
    }
    .avt-services-row-05 h3 {
        transform: unset;
    }
    .avt-term-content-sec .avt-content-wrap {
        padding: 20px 5px;
        margin-top: 20px;
    }
    .avt-term-banner-sec h2 {
        padding: 0 5px;
    }
    /* ** */
    .avt-animate-wrap .avt-services-row {
        width: 100%;
    }
    .avt-home-services-img-wrap img {
        width: auto;
    }
    .avt-why-choose-sec {
        height: auto;
        padding: 0;
    }
    .avt-why-choose-sec .sticky {
        position: static !important;
    }
    .avt-why-choose-wrap {
        transform: scale(1) !important;
    }
    .avt-animate-wrap .avt-animate-cls div {
        width: 95%;
        margin: 0 auto;
    }
    .avt-middle-row ul li a {
        font-size: 25px;
    }
    .avt-menu-contact-btn {
        display: none;
    }
    .avt-web-services-cls {
        display: none;
    }
    .avt-mobile-menu-cls {
        display: block;
    }
    .avt-mobile-menu-cls .navbar-collapse.collapse {
        display: block;
    }
    .avt-mobile-menu-cls .navbar-toggler {
        display: none;
    }
    .avt-mobile-menu-cls .nav-link {
        font-size: 35px !important;
        font-family: Inter;
        font-weight: 400;
        line-height: 2.0em !important;
        color: #fff !important;
        background-color: transparent !important;
    }
    .avt-mobile-menu-cls .nav-item a.dropdown-item {
        font-size: 20px !important;
        font-family: Inter;
        font-weight: 200;
        line-height: 2.0em !important;
        color: #A1A1A1 !important;
        background-color: transparent !important;
        opacity: 0.6;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link::after {
        display: none;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::before {
        right: -38px;
        top: 3px;
        bottom: 0;
        margin: auto 0;
        background-repeat: no-repeat;
        transition: all 0.3;
        background-size: 16px;
    }
    .avt-mobile-menu-cls .navbar-nav .dropdown-menu {
        text-align: center;
        background-color: transparent;
        border: none;
    }
    .avt-mobile-menu-cls .dropdown-divider {
        border: none;
    }
    .avt-btm-row .avt-footer-icons-list ul {
        justify-content: center;
    }
    #navbar-toggler {
        display: none;
    }
    .avt-mega-menu .container-fluid {
        padding-bottom: 30px;
    }
    .avt-middle-row h3 {
        font-size: 30px;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        display: none;
    }
    .avt-about-values-sec-dark {
        background-size: 59%;
        background-position: 0px 30%;
    }
    .avt-about-whywork-sec {
        background-size: 79%;
        background-position: 0px 30%;
    }
    .avt-values-sec {
        padding: 40px 0;
    }
    .avt-service-whywork-sec {
        background-size: 79%;
        background-position: 0px 30%;
    }
    .avt-service-web-sec {
        background-size: 79%;
        background-position: 0px 30%;
        padding-bottom: 40px;
    }
    .avt-web-sec .avt-values-sec-wrap {
        top: 0px !important;
    }
    .avt-our-services-sec h2 {
        font-size: 62px;
    }
    /* ** */
    .avt-middle-row {
        padding: 0;
    }
    .avt-mega-menu .avt-middle-row .avt-right-col img {
        display: none;
    }
    .avt-middle-row,
    .avt-middle-row .col-lg-3 {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between; */
        /* height: 100%; */
        padding: 20px 16px;
    }
    .avt-mega-menu .container-fluid {
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
    }
    ul.avt-resp-social-list.avt-header-social-list {
        display: block;
    }
    ul.avt-resp-social-list {
        margin: 30px 0 30px 0;
        flex-direction: column;
    }
    .avt-resp-social-list span {
        font-weight: 400;
        font-family: 'Inter';
        font-size: 18px;
        text-transform: lowercase;
        color: #A1A1A1 !important;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::before {
        right: 15px;
        margin: 12px 0 0 0;
    }
    .avt-mega-menu .container-fluid {
        padding: 10px 0px 10px 0px;
    }
    .avt-mobile-menu-cls {
        display: block;
        max-height: 400px;
        overflow-x: auto;
    }
    .avt-testimonials-sec .carousel .carousel-inner {
        height: 660px;
    }
    .avt-testimonials-sec .carousel-indicators {
        bottom: -25px;
        width: fit-content;
        left: 0px;
        right: 0px;
    }
    /* *** */
    .modal-dialog {
        max-width: 100%;
    }
    .modal-content {
        max-width: 100%;
        padding: 20px;
        border-radius: 30px;
        overflow: hidden;
    }
    .avt-popup2-contact-wrap .avt-popup-form-wrap .avt-popup-form-heading-cls {
        font-size: 24px;
        padding: 30px;
    }
    .avt-popup2-contact-wrap .avt-input-wrap input,
    .avt-popup2-contact-wrap .avt-input-wrap textarea {
        font-size: 16px;
    }
    .avt-popup2-contact-wrap .avt-popup-form-wrap .avt-popup-form-heading-cls {
        margin-bottom: 0px;
    }
    .avt-popup2-contact-wrap .avt-contact-form-wrap {
        padding: 10px 40px 20px 40px;
        max-height: 400px;
        overflow-y: auto;
    }
    .avt-popup2-contact-wrap .avt-submit-wrap button.avt-btn {
        font-size: 16px;
    }
    .modal-header .btn-close {
        padding: 10px !important;
    }
    .modal-header .btn-close {
        right: 40px;
        top: 80px;
    }
    .avt-contact-form-wrap .avt-submit-wrap {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {
    .avt-header-wrap {
        padding: 30px 6px 25px 6px;
        background-image: none;
        background-color: #000;
    }
    .avt-home-brands-sec .row p {
        padding: 10px 20px;
    }
    img.avt-home-service-1-bg-img {
        left: 0px !important;
    }
    img.avt-home-service-2-bg-img {
        /* left: 0px !important; */
        display: none;
    }
    .avt-services-row .avt-home-services-img-wrap img {
        width: 90%;
    }
    .avt-services-row .avt-home-services-img-wrap {
        text-align: center;
    }
    .avt-services-row-02 .avt-home-services-img-wrap {
        width: 100%;
    }
    .avt-services-row-02 .avt-row-2-col-1 {
        order: 2 !important;
    }
    .avt-services-row-04 .avt-row-4-col-1 {
        order: 2 !important;
    }
    .avt-why-choose-wrap .row {
        padding: 0 4px;
    }
    .avt-why-choose-sec .avt-why-choose-list-wrap ul li h2 {
        font-size: 35px;
    }
    .avt-home-brands-sec .row h2 {
        font-size: 38px;
        line-height: 1.2em;
        padding: 0 10px;
    }
    .avt-portfolio-sec {
        padding: 80px 0px;
    }
    .avt-portfolio-sec .row {
        padding: 0;
        margin: 0 auto;
    }
    .avt-portfolio-sec .avt-portfolio-top-row h2 {
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 1.2em;
    }
    .avt-portfolio-sec .text-right a.avt-btn {
        margin: 25px auto 0 0;
    }
    .avt-portfolio-top-row .col-lg-6 {
        padding: 0;
    }
    .avt-testimonials-sec .avt-testimonials-main-wrap h4 {
        font-size: 20px;
        line-height: 1.4em;
        padding: 0 10px;
    }
    .avt-testimonials-sec {
        padding: 40px 0;
        min-height: auto;
    }
    .avt-testimonials-sec .avt-testimonial-img-wrap {
        width: 150px;
        height: 150px;
    }
    .avt-testimonials-sec .avt-testimonial-img-wrap .avt-testimonail-img {
        width: 120px;
    }
    .avt-testimonials-sec .avt-testimonial-img-wrap {
        margin-bottom: 10px;
    }
    .avt-testimonials-sec .avt-testimonials-designation {
        padding: 10px 0;
    }
    .avt-testimonials-sec .carousel-indicators {
        margin: 0 auto;
        bottom: -5px;
    }
    .avt-testimonials-sec .avt-testimonials-main-wrap h6 {
        text-align: center;
        margin-top: 20px;
    }
    .avt-footer-sec .avt-footer-logo-wrap h2 {
        font-size: 40px;
        line-height: 1.2em;
    }
    .avt-footer-sec {
        padding-top: 60px;
    }
    .avt-footer-menu {
        padding: 10px 0;
    }
    .avt-footer-menu ul {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        column-gap: 30px;
        justify-content: center;
        flex-direction: column;
        row-gap: 20px;
    }
    /* about */
    .avt-about-banner .row {
        padding: 0 8px;
    }
    .avt-about-banner .avt-content-wrap h1 {
        line-height: 1.0em;
    }
    .avt-about-banner .avt-content-wrap h1 span {
        font-size: 40px;
        line-height: 1.0em;
    }
    .avt-about-process-sec {
        padding: 40px 5px;
    }
    .avt-about-sec .row {
        padding: 0 4px;
    }
    .avt-about-process-sec h2,
    .avt-about-process-sec h2.active {
        font-size: 40px;
        line-height: 1.2em;
    }
    .avt-about-process-sec .avt-process-img-wrap {
        padding: 15px 0;
    }
    .avt-values-sec .avt-values-sec-wrap {
        position: relative;
        z-index: 10;
        padding: 0 7px;
    }
    .avt-values-sec .avt-values-inner-wrap {
        margin-bottom: 20px;
    }
    .avt-value-main-heading::before {
        font-size: 100px;
        left: 10px;
        top: 0px;
        bottom: 10px;
    }
    .avt-values-sec {
        padding: 20px 0 240px 0;
    }
    .avt-about-why-work-sec {
        padding: 40px 0 200px 0;
        margin: 0px;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        font-size: 60px;
        left: 15px;
        letter-spacing: 0.7px;
        bottom: 10px;
    }
    .avt-contact-sec {
        padding: 0px 6px;
    }
    .avt-contact-form-wrap input,
    .avt-contact-form-wrap textarea {
        font-size: 20px !important;
    }
    .container.avt-full-container-img {
        padding: 0 18px;
    }
    .avt-our-services-sec h2 {
        font-size: 40px;
        line-height: 1.2em;
    }
    .avt-our-services-sec .avt-expertise-wrap .accordion-button {
        font-size: 30px;
    }
    .avt-our-services-sec .row {
        padding: 0 7px;
    }
    .accordion-button::after {
        content: '+' !important;
        background-image: none !important;
        color: #000;
        font-size: 25px;
        font-weight: 600;
        position: absolute;
        top: 28%;
        right: 0;
        transform: none !important;
    }
    .avt-our-services-sec .avt-full-container-img img {
        margin: 40px 0 40px 0;
    }
    .avt-expertise-wrap .avt-btn {
        margin: 40px auto;
    }
    /* .avt-values-sec.avt-why-work-sec {
        padding: 20px 0 0px 0;
    } */
    .accordion-item {
        padding: 10px 0;
    }
    .avt-web-sec {
        padding-bottom: 110px;
    }
    .avt-web-sec .avt-value-main-heading::before {
        font-size: 60px;
        left: 15px;
        letter-spacing: 0.7px;
        bottom: 20px;
    }
    .avt-blogs-repeater-row.row {
        margin-top: 0px;
        padding: 40px 0;
    }
    .avt-blog-img-wrap img {
        border: 1px solid #eee;
    }
    .avt-blog-banner-sec h1 {
        font-size: 40px;
        padding-top: 10px;
        line-height: 1.2em;
    }
    .avt-blog-banner-sec {
        height: auto;
        padding: 120px 0 20px 0;
    }
    .avt-blogs-repeater-row.row {
        margin-top: 0px;
        padding: 60px 5px 20px 5px;
    }
    .avt-case-study-sec {
        padding-bottom: 0px;
    }
    .avt-about-banner .avt-content-wrap h1 {
        font-size: 40px;
        line-height: 1.2em;
        padding-top: 10px;
    }
    .avt-about-banner {
        padding-bottom: 45px;
    }
    .avt-our-services-sec p {
        font-size: 14px;
    }
    .avt-case-study-banner-sec {
        padding: 20px 18px 20px 18px;
    }
    .avt-case-study-quote-sec {
        padding: 60px 10px 20px 10px;
    }
    .avt-case-study-quote-sec h3 {
        font-size: 25px;
        line-height: 1.4em;
    }
    .avt-case-study-nav-sec h3 {
        font-size: 25px;
        line-height: 1.4em;
    }
    .avt-case-main-banner-sec-v2 img {
        margin-top: 0px;
        margin-bottom: 40px;
    }
    .avt-case-study-below-banner-row-v2 .col-lg-8,
    .avt-case-study-below-banner-row-v2 .col-lg-4 {
        padding: 0 5px;
    }
    .avt-case-study-below-banner-row-v2 .avt-right-col h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .avt-case-study-below-banner-row-v2 .avt-right-col p {
        font-size: 16px;
    }
    .avt-case-study-below-banner-sec .avt-case-study-below-banner-row {
        padding: 30px 0;
    }
    .avt-footer-sec .avt-footer-logo-wrap img {
        width: 100%;
    }
    .avt-sidebar {
        margin-left: 0px;
        padding: 0 6px 40px 6px;
    }
    .avt-blog-banner-sec h1 {
        padding: 10px 8px 0px 8px;
    }
    .avt-checkbox-wrap {
        grid-template-columns: auto;
    }
    .avt-checkbox-wrap .avt-check-box {
        margin: 10px 0;
    }
    .avt-checkbox-wrap .avt-check-box [type="checkbox"] {
        margin: 0 0 0 auto;
    }
    .avt-contact-sec {
        padding-top: 100px;
    }
    .avt-services-02-img {
        position: absolute;
        right: 0;
        text-align: right;
        bottom: auto;
        animation: bg_service_2animate 7s linear infinite;
        top: 50px;
    }
    .avt-services-row-02 p,
    .avt-services-row-02 .avt-btn {
        transform: translateX(0px) !important;
    }
    .avt-home-about-sec h3 {
        margin-top: 30px;
        font-size: 30px;
    }
    .avt-about-sec .row .avt-right-col .avt-content-wrap h6 {
        margin-bottom: 30px;
        line-height: 29px;
    }
    .avt-values-sec .avt-values-sec-wrap {
        top: 0;
    }
    .avt-values-sec {
        padding: 20px 0 20px 0;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        opacity: 0.1;
    }
    .avt-contact-sec {
        padding-top: 40px;
    }
    img.avt-home-service-1-bg-img {
        width: 75px !important;
        top: 60px !important;
        left: 5px !important;
    }
    .avt-why-choose-wrap {
        height: auto;
        scale: 1 !important;
        transform: unset !important;
        rotate: unset !important;
        translate: none !important;
    }
    .avt-testimonials-sec .carousel {
        height: 650px;
    }
    .avt-footer-sec .avt-btn {
        top: -20px;
    }
    .avt-about-sec::before {
        content: '';
        background-size: 118%;
        background-repeat: no-repeat;
        background-position: center 50px;
        width: 100%;
        height: 100%;
        z-index: 0;
        left: 12px;
        opacity: 0.4;
        transition: all 1.8s;
        -webkit-animation: none;
        top: -92px;
    }
    .avt-about-sec:hover::before {
        -webkit-transform: rotate(0deg);
    }
    .avt-about-sec {
        background-position: center right;
    }
    .avt-values-sec .avt-values-sec-wrap {
        top: 0;
        padding-top: 200px;
    }
    .avt-why-work-sec .avt-value-main-heading::before {
        top: 25px;
    }
    .avt-our-services-sec .avt-expertise-wrap .accordion-button {
        font-size: 22px;
        padding-right: 40px;
    }
    .avt-web-sec h4 {
        font-size: 30px;
        line-height: 1.4em;
    }
    .avt-values-sec .avt-values-sec-wrap {
        padding-top: 115px;
    }
    .avt-web-sec .avt-value-main-heading::before {
        top: 30px
    }
    .avt-values-sec.avt-why-work-sec {
        padding: 10px 0 80px 0;
    }
    .avt-web-sec h4 {
        font-size: 30px;
        line-height: 1.4em;
        padding-top: 30px;
    }
    .avt-blog-img-wrap img {
        min-height: auto;
    }
    .avt-case-main-banner-sec-v2 {
        padding-top: 180px;
    }
    .avt-case-study-below-banner-row-v2 .row .col-lg-6 {
        padding: 0 6px;
    }
    .avt-case-study-nav-sec .avt-case-study-nav-row {
        padding: 0 20px;
    }
    .avt-case-study-nav-sec h3 {
        font-size: 25px;
    }
    .avt-about-values-sec {
        padding-top: 80px;
    }
    .avt-values-sec.avt-why-work-sec .avt-btn {
        position: relative;
        left: 8px;
        top: 8px;
    }
    .avt-services-02-img {
        right: 100px;
    }
    .avt-services-row .avt-home-services-img-wrap img {
        top: -15px;
        left: -24px;
    }
    .avt-expertise-wrap .accordion-body {
        padding: 0 0px;
    }
    .avt-accordion-list-wrap {
        padding-top: 10px;
    }
    /* ** */
    .avt-animate-wrap .avt-animate-cls div:last-child {
        width: 98%;
        margin: 0 auto;
    }
    .avt-portfolio-box .avt-portfolio-hover-btn img {
        width: 50px;
        height: 50px;
    }
    .avt-home-services-sec .avt-home-services-top-row h2 {
        font-size: 35px;
    }
    .avt-home-services-sec .avt-home-services-top-row h6 {
        font-size: 16px;
    }
    .avt-about-whywork-sec .avt-values-sec-wrap {
        padding: 40px 0 0 0;
    }
    .avt-service-whywork-sec .avt-values-sec-wrap {
        padding-top: 15px;
    }
    .avt-web-sec .avt-values-sec-wrap {
        padding-top: 15px;
    }
    .avt-web-sec {
        padding-bottom: 20px;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link {
        font-size: 26px !important;
        line-height: 1.8em !important;
    }
    .avt-mega-menu .avt-project-btn {
        font-size: 26px;
    }
    .avt-mobile-menu-cls .nav-item a.dropdown-item {
        font-size: 16px !important;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle::before {
        margin: 2px 0 0 0;
    }
    .avt-mega-menu-main-cls .navbar-nav .nav-link.dropdown-toggle.show::before {
        right: 45px;
    }
    .avt-resp-social-list span {
        font-size: 14px;
    }
    .avt-resp-social-list img {
        width: 20px;
    }
    .avt-animate-wrap .avt-animate-cls div {
        width: 99.7% !important;
    }
    .avt-popup2-contact-wrap .avt-input-wrap input,
    .avt-popup2-contact-wrap .avt-input-wrap textarea {
        font-size: 16px !important;
        padding: 0 0 10px 0;
    }
    .modal-header .btn-close {
        top: 90px;
    }
    .modal-header .btn-close,
    .modal-header .btn-close::after {
        font-size: 14px;
    }
    .avt-popup2-contact-wrap .avt-popup-form-wrap .avt-popup-form-heading-cls {
        padding: 40px 40px 20px 40px !important;
    }
    .avt-popup2-contact-wrap .avt-contact-form-wrap {
        max-height: 300px;
        overflow-y: auto;
        padding-top: 20px;
        padding-bottom: 45px;
    }
    .avt-popup2-contact-wrap .avt-contact-form-wrap .avt-btn::before {
        width: 46px;
        height: 46px;
    }
    .avt-popup2-contact-wrap.avt-popup-contact-wrap {
        border-radius: 0px;
    }
    .avt-popup2-contact-wrap .avt-contact-form-wrap {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width: 480px) {
    .avt-services-row .avt-home-services-img-wrap img {
        top: 20px;
        left: 0px;
    }
    .avt-services-row-01 h3 {
        padding-top: 40px;
    }
    .avt-services-02-img {
        right: 20px;
        bottom: 20px;
    }
    .avt-services-row-02 .avt-home-services-img-wrap {
        top: 43px;
        left: -23px;
    }
    .avt-about-sec .avt-about-img-wrap img {
        left: 0px;
    }
    .avt-about-sec::before {
        content: '';
        background-size: 118%;
        background-repeat: no-repeat;
        background-position: center 50px;
        width: 100%;
        height: 100%;
        z-index: 0;
        left: 12px;
        opacity: 0.4;
        transition: all 1.8s;
        -webkit-animation: none;
        top: -92px;
    }
    .avt-about-values-sec .avt-values-sec-wrap {
        padding-top: 40px;
        padding-bottom: 80px;
    }
    .avt-services-row-02 .avt-row-2-col-1 {
        padding-top: 20px;
    }
    .avt-about-banner.avt-services-banner.avt-case-main-banner-sec-v1 {
        height: auto;
    }
    .avt-services-row .avt-home-services-img-wrap img {}
    .avt-home-service-4-bg-img {
        display: none;
    }
    .avt-home-service-1-bg-img {
        display: none;
    }
    img.avt-home-service-1-bg-img {
        top: 6px !important;
    }
    img.avt-home-service-4-bg-img {
        left: 5px !important;
        top: -9px !important;
    }
    /* animation */
    .avt-animate-wrap .avt-services-row {
        width: auto;
    }
    .avt-animate-wrap .avt-services-row {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .avt-home-services-sec {
        margin-bottom: -1700px;
    }
    /* *** */
    .avt-animate-wrap .avt-animate-cls div:last-child {
        width: 98%;
        margin: 0 auto;
        margin-top: 30px;
    }
    .avt-contact-banner-v2-sec h1 {
        font-size: 40px;
        line-height: 1.4em;
        padding-left: 7px;
    }
    .avt-contact-banner-v2-sec h6 {
        padding-left: 7px;
        padding-bottom: 10px;
    }
    .avt-contact-banner-v2-sec .contact-list-wrap ul {
        display: flex;
        column-gap: 0;
        list-style-type: none;
        flex-direction: column;
        column-gap: 60px;
        row-gap: 60px;
        padding: 0 8px;
    }
    .avt-contact-banner-v2-form-sec .avt-form-wrap-v2 {
        padding: 20px 40px;
    }
    .avt-contact-banner-v2-form-sec {
        height: 500px;
    }
    .avt-contact-banner-v2-sec .contact-list-wrap ul li a {
        font-size: 17px;
    }
    .avt-animate-1-cls {
        height: 0px;
    }
    .avt-home-services-sec {
        margin-bottom: -700px;
    }
    .avt-animate-1-cls {
        height: 0 !important;
    }
    .avt-about-values-sec .avt-value-main-heading::before {
        top: 40px;
    }
    .avt-web-sec {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .avt-contact-sec.avt-popup-contact-wrap {
        height: 400px;
        overflow-x: scroll;
    }
    .avt-about-banner .avt-btn {
        position: relative;
        z-index: 999;
    }
    /* ** */
    .avt-about-values-sec {
        padding-top: 0px;
    }
    .avt-web-sec h4 {
        padding: 0px 0;
    }
    .avt-service-web-sec p {
        display: none;
    }
}


/* merge */

.avt-web-sec {
    background-color: #FFFFFF !important;
}

.avt-values-sec {
    background-color: #000;
}

.avt-why-work-sec {
    background-color: #fff !important;
}

.avt-why-work-sec .avt-values-inner-wrap h3 {
    color: #000 !important;
}

.avt-why-work-sec .avt-values-inner-wrap p {
    color: #000 !important;
}

.avt-values-sec .avt-values-inner-wrap h3 {
    color: #fff;
}

.avt-values-sec .avt-values-inner-wrap p {
    color: #fff;
}

.avt-value-main-heading::before {
    color: #F8F8F8;
}

.avt-why-work-sec .avt-value-main-heading::before {
    color: #797575 !important;
    z-index: 999;
}

.avt-why-work-sec .avt-value-main-heading::before {
    bottom: -60px;
}

.avt-about-values-sec .avt-values-sec-wrap {
    top: 60px;
}

.avt-about-values-sec .avt-value-main-heading::before {
    bottom: -150px;
}

.avt-about-why-work-sec {
    padding: 220px 0;
}

.avt-web-sec .avt-value-main-heading::before {
    color: #797575;
    left: 20px;
}

.avt-web-sec .avt-values-sec-wrap {
    position: relative;
    z-index: 10;
    top: 120px;
}

@media (max-width: 1440px) {
    .avt-why-work-sec {
        padding: 120px 0;
    }
    .avt-why-work-sec .avt-values-sec-wrap {
        top: 20px
    }
    .avt-value-main-heading::before {
        bottom: -180px;
    }
}